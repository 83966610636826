import React from "react";
import "./Screen.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "react-bootstrap";
import V1 from "../assets/images/s1.png";
import V2 from "../assets/images/s2.png";
import V3 from "../assets/images/s3.png";
import V4 from "../assets/images/s4.png";
import V5 from "../assets/images/s5.png";
import V6 from "../assets/images/s6.png";
import V7 from "../assets/images/s7.png";
import V8 from "../assets/images/s8.png";
import V9 from "../assets/images/s9.png";
import V10 from "../assets/images/s10.png";
import V11 from "../assets/images/s11.png";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import Slider from "react-slick";

function Screen() {

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    className: "center",
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1030,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 500,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
    ]
  };

  return (
    <>
      <Container id="Screens">
        <div className="screen-heading">Unique App Screens</div>
        <div className="slider-container">
          <Slider {...settings}>
            <div className="div_css">
              <img src={V1} className="slide-img"/>
            </div>
            <div className="div_css">
              <img src={V11} className="slide-img"/>
            </div>
            <div className="div_css">
               <img src={V3} className="slide-img"/>
            </div>
            <div className="div_css">
               <img src={V4} className="slide-img" />
            </div>
            <div className="div_css">
              <img src={V5} className="slide-img"/>
            </div>
            <div className="div_css">
                <img src={V6} className="slide-img"/>
            </div>
            <div className="div_css">
              <img src={V7} className="slide-img"/>
            </div>
            <div className="div_css">
              <img src={V8} className="slide-img"/>
            </div>
            <div className="div_css">
              <img src={V9} className="slide-img"/>
            </div>
            <div className="div_css">
              <img src={V10} className="slide-img"/>
            </div>
            <div className="div_css">
               <img src={V2} className="slide-img"/>
            </div>
          </Slider>
        </div>
      </Container>
    </>
  );
}

export default Screen;
