import React from 'react'
import About from '../About/About'
import Download from '../Download/Download'
import Feature from '../Feature1/feature'
import Home from '../Home/Home'
import Privacy from '../Privacy/Privacy'
import Screen from '../Screen/Screen'

function Main() {
  return (
    <>
     <Home/>
      <Feature/>
      <About/>
      <Screen/>
      <Privacy/>
      <Download/> 
    </>
  )
}

export default Main
