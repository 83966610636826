import React from 'react'
import './About.css'

function About() {
  return (
    <>
    <div className='about-main' id='About'>
      <div className='about-heading'>About Friendzy App</div>
      <p className='about-para'>
      Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci omnis voluptatibus, id esse reiciendis explicabo. Nesciunt minima magni iure, reiciendis doloribus quidem dicta molestias mollitia autem enim id! Maiores voluptates minima sequi est amet, repudiandae possimus rerum sapiente laboriosam odio nesciunt porro eaque praesentium cupiditate similique vitae, vel quaerat quae consequuntur recusandae. Illum impedit eveniet possimus ratione! Architecto saepe provident maiores deleniti fuga repudiandae, libero quo quam consequatur ea natus magnam et rem sequi. Vel odio nobis ad vero rerum nihil sed doloremque, voluptates, assumenda quas necessitatibus enim? Libero atque amet corrupti. Vel id, itaque reprehenderit asperiores, repudiandae laborum cumque tempore quas consectetur deleniti libero! Velit, delectus. Dolorum quo voluptatem temporibus, repudiandae accusamus perspiciatis totam quod. Dolor tenetur eius pariatur laboriosam ea nemo sunt, facilis fuga illum cum quos quas porro labore doloribus molestiae nobis, suscipit officiis atque reprehenderit fugit eveniet veritatis? 
      </p>
    </div>
    
    </>
  )
}

export default About
