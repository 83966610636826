import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Footer.css";
import logoHeader from "../../assets/images/friendzy-logo.png";
import { Link } from "react-router-dom";
import { GoDotFill } from "react-icons/go";

function Footer() {
  return (
    <>
      <div className="footer-main">
        <Container>
          <Row>
            <hr className="hr-line" />
            <Col lg="8" md="8" className="footer-data">
              <div className="copyright">
                © 2024 <span style={{ fontWeight: "500" }}>Friendzy</span> All
                Rights Reserved. Designed by CreativeInfoway{" "}
                <span style={{ margin: "8px" }}>
                  <Link to="/privacypolicy" className="footer-link">
                    <GoDotFill />
                    Privacy Policy
                  </Link>
                </span>
                <span>
                  <Link to="/termsandcondition" className="footer-link">
                    <GoDotFill />
                    Terms & Conditions
                  </Link>
                </span>
              </div>
            </Col>
            <Col lg="4" md="4" className="footer-data">
              <div>
                <img src={logoHeader} className="header-logo" />
                <span className="logo-txt">Friendzy</span>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Footer;
