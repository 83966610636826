import React, { useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { toast } from "react-toastify";
import "./ReqestForm.css";
import imageSrc from "../assets/images/friendzy-logo.png";
import { axiosHelper } from "../changepass";

const initialFormState = {
  name: "",
  email: "",
  phone: "",
  reason: "",
  describe_reason: "",
};

function RequestForm() {
  const [formValues, setFormValues] = useState(initialFormState);
  const [errors, setErrors] = useState({});

  const onInputChange = (e) => {
    const updatedErrors = { ...errors };
    if (updatedErrors[e.target.name]) {
      updatedErrors[e.target.name] = "";
      setErrors(updatedErrors);
    }
    setFormValues({ ...formValues, [e.target.name]: e.target.value });
  };

  const validateForm = () => {
    let formErrors = {};

    if (!formValues.name) {
      formErrors.name = "Please enter your name";
    }

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const body = {
      name: formValues.name,
      email: formValues.email,
      phone: formValues.phone,
      reason: formValues.reason,
      describe_reason: formValues.describe_reason,
    };
    const result = await axiosHelper(
      `/v1/user/delete/account/request`,
      "POST",
      body,
      undefined
    );
    if (result.status === 1) {
      toast.success(result.message);
      setFormValues(initialFormState);
    } else if (result.status === 0) {
      toast.error(result.message);
    }
  };

  return (
    <section className="dashboard_body">
      <div
        class="right_col utillity_height"
        role="main"
        style={{ paddingBottom: "0" }}
      >
        <div class="form-container">
          <div class="row">
            <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="x_panel">
                <div class="x_content">
                  <form id="new-payee-frm" onSubmit={handleSubmit}>
                    <div className="row">
                      <Col md="12" lg="12" style={{ marginTop: "1rem" }}>
                        <div
                          style={{ display: "flex" }}
                          className="header-area"
                        >
                          <img
                            src={imageSrc}
                            alt="Background"
                            style={{
                              width: "50px",
                              height: "auto",
                              marginLeft: "0px",
                            }}
                          />
                          <div className="form-text">
                            Delete Account Request Form
                          </div>{" "}
                        </div>
                        <h5 className="text-center">
                          {" "}
                          Please submit the form, and our support team will be
                          happy to assist you.
                        </h5>
                      </Col>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group ">
                          <label className="form-lbl">Name :</label>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Enter Your Name"
                            name="name"
                            value={formValues.name}
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group ">
                          <label className="form-lbl">Email :</label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder="Enter Email"
                            name="email"
                            value={formValues.email}
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group ">
                          <label className="form-lbl">Phone :</label>
                          <br></br>
                          <input
                            type="text"
                            className="form-control "
                            placeholder="Enter Phone"
                            name="phone"
                            value={formValues.phone}
                            onChange={(e) => onInputChange(e)}
                            pattern="[0-9]{10}"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group ">
                          <label className="form-lbl">Reason :</label>
                          <br></br>
                          <select
                            className="form-contro"
                            name="reason"
                            value={formValues.reason}
                            onChange={(e) => onInputChange(e)}
                          >
                            <option value="">Select Reason</option>
                            <option value="Privacy Concerns">
                              Privacy Concerns
                            </option>
                            <option value="Not Using the Service">
                              Not Using the Service
                            </option>
                            <option value="Created a New Account">
                              Created a New Account
                            </option>
                            <option value="Prefer a Different Service">
                              Prefer a Different Service
                            </option>
                            <option value="Security Concerns">
                              Security Concerns
                            </option>
                            <option value="Too Many Notifications/Emails">
                              Too Many Notifications/Emails
                            </option>
                            <option value="Difficult to Use">
                              Difficult to Use
                            </option>
                            <option value="Poor Customer Service">
                              Poor Customer Service
                            </option>
                            <option value="Limited Features or Functionality">
                              Limited Features or Functionality
                            </option>
                            <option value="Cost-Related Issues">
                              Cost-Related Issues
                            </option>
                            <option value="Technical Issues or Bugs">
                              Technical Issues or Bugs
                            </option>
                            <option value="Concerns About Content or Community">
                              Concerns About Content or Community
                            </option>
                            <option value="Personal Reasons">
                              Personal Reasons
                            </option>
                            <option value="Data Deletion Requests">
                              Data Deletion Requests
                            </option>
                            <option value="Ethical or Moral Reasons">
                              Ethical or Moral Reasons
                            </option>
                            <option value="Too Many Ads">Too Many Ads</option>
                            <option value="Dissatisfaction with Premium Services">
                              Dissatisfaction with Premium Services
                            </option>
                            <option value="Not Enough Features">
                              Not Enough Features
                            </option>
                            <option value="Unwanted Promotions">
                              Unwanted Promotions
                            </option>
                            <option value="Other">Other</option>
                            {/* Add more options as needed */}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group ">
                          <label className="form-lbl">Describe Reason :</label>
                          <br></br>
                          <input
                            type="text"
                            className="form-control "
                            placeholder=" Enter describe reason"
                            name="describe_reason"
                            value={formValues.describe_reason}
                            onChange={(e) => onInputChange(e)}
                            required
                          />
                        </div>
                      </div>
                      <Col
                        lg="12"
                        md="12"
                        style={{ display: "flex", justifyContent: "start" }}
                      >
                        <button type="submit" className="btn btn-success">
                          Submit
                        </button>
                      </Col>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default RequestForm;
