import React from "react";
import { Link } from "react-router-dom";

const Term = () => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 col-sm-12 col-xs-12">
          <div className="safty-data">
            <div className="">
              <div className="privacy-heading">Terms of Service</div>
              <p className="safty-para">
                Friendzy provides private messaging services to users worldwide.
                By installing or using our apps, services, or website (together,
                “Services”), you agree to our Terms of Service (“Terms”).
              </p>
            </div>
            <div className="">
              <h5>About our services</h5>
              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Minimum Age.</b> You must be at
                least 18 years old to use our Services. The minimum age to use
                our Services without parental approval may be higher in your
                home country.
              </p>
              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Account Registration. </b>To create an account, you must register for our Services using your email address. Users must provide accurate and complete information when creating an account. This ensures that our user database is populated with authentic profiles, enhancing the overall user experience and fostering trust within our community.
              </p>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Privacy of user data.</b>{" "}
                Friendzy does not sell, rent, or monetize your personal data or
                content in any way – ever.
              </p>

              <p className="safty-para">
                Please read our <Link to="/privacypolicy">Privacy Policy</Link>{" "}
                to understand how we safeguard the information you provide when
                using our Services. For the purpose of operating our Services,
                you agree to our data practices as described in our Privacy
                Policy.
              </p>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Software. </b>
                In order to enable new features and enhanced functionality, you
                consent to downloading and installing updates to our Services.
              </p>
              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Fees and Taxes. </b>
                You are responsible for data and mobile carrier fees and taxes
                associated with the devices you use to access our Services.
              </p>
            </div>

            <div className="pt-3">
              <h5>Using Friendzy</h5>

              <p className="safty-para pt-2">
                <b style={{ fontWeight: "600" }}>Our Terms and Policies. </b>
                You must use our Services according to our Terms and posted
                policies. If we disable your account for a violation of our
                Terms, you will not create another account without our
                permission.
              </p>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Legal and Acceptable Use. </b>
                You agree to use our Services only for legal, authorized, and
                acceptable purposes. You will not use (or assist others in
                using) our Services in ways that: (a) violate or infringe the
                rights of Friendzy, our users, or others, including privacy,
                publicity, intellectual property, or other proprietary rights;
                (b) involve sending illegal or impermissible communications such
                as bulk messaging, auto-messaging.
              </p>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Harm to Friendzy. </b>
                You must not (or assist others to) access, use, modify,
                distribute, transfer, or exploit our Services in unauthorized
                manners, or in ways that harm Friendzy, our Services, or
                systems. For example, you must not: (a) gain or try to gain
                unauthorized access to our Services or systems; (b) disrupt the
                integrity or performance of our Services; (c) create accounts
                for our Services through unauthorized or automated means; (d)
                collect information about our users in any unauthorized manner;
                or (e) sell, rent, or charge for our Services.
              </p>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>
                  Keeping Your Account Secure.{" "}
                </b>
                Friendzy embraces privacy by design and does not have the
                ability to access your messages. You are responsible for keeping
                your device and your Friendzy account safe and secure.
              </p>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Prohibited Activities. </b>
                (a) Use the application for any illegal or unauthorized purpose.
                (b) Harass, abuse, or harm other users. (c) Use the application
                to distribute spam or unsolicited communications. (d) Interfere
                with or disrupt the operation of the application or the servers
                or networks connected to the application.
              </p>
            </div>

            <div className="pt-3">
              <h5>Your Rights and License with Friendzy</h5>
              <p className="safty-para pt-2">
                <b style={{ fontWeight: "600" }}>Your Rights. </b>
                You own the information you submit through our Services. You
                must have the rights to the email address you use to sign up for
                your Friendzy account.
              </p>

              <p className="safty-para pt-2">
                <b style={{ fontWeight: "600" }}>Your Responsibility. </b>
                You are solely responsible for any content you post or transmit
                through the application.
              </p>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Friendzy’s Rights. </b>
                We own all copyrights, trademarks, domains, logos, trade dress,
                trade secrets, patents, and other intellectual property rights
                associated with our Services. You may not use our copyrights,
                trademarks, domains, logos, trade dress, patents, and other
                intellectual property rights unless you have our written
                permission. To report copyright, trademark, or other
                intellectual property infringement, please contact us.
                {/* , please contact
                abuse@Friendzy.org. */}
              </p>
              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Friendzy’s License to You. </b>
                Friendzy grants you a limited, revocable, non-exclusive, and
                non-transferable license to use our Services in accordance with
                these Terms.
              </p>
            </div>
            <div className="">
              <h5>Disclaimers and Limitations</h5>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Disclaimers. </b>
                YOU USE OUR SERVICES AT YOUR OWN RISK AND SUBJECT TO THE
                FOLLOWING DISCLAIMERS. WE PROVIDE OUR SERVICES ON AN “AS IS”
                BASIS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT
                NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
                PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND FREEDOM FROM
                COMPUTER VIRUS OR OTHER HARMFUL CODE. Friendzy DOES NOT WARRANT
                THAT ANY INFORMATION PROVIDED BY US IS ACCURATE, COMPLETE, OR
                USEFUL, THAT OUR SERVICES WILL BE OPERATIONAL, ERROR-FREE,
                SECURE, OR SAFE, OR THAT OUR SERVICES WILL FUNCTION WITHOUT
                DISRUPTIONS, DELAYS, OR IMPERFECTIONS. WE DO NOT CONTROL, AND
                ARE NOT RESPONSIBLE FOR, CONTROLLING HOW OR WHEN OUR USERS USE
                OUR SERVICES. WE ARE NOT RESPONSIBLE FOR THE ACTIONS OR
                INFORMATION (INCLUDING CONTENT) OF OUR USERS OR OTHER THIRD
                PARTIES. YOU RELEASE US, AFFILIATES, DIRECTORS, OFFICERS,
                EMPLOYEES, PARTNERS, AND AGENTS (TOGETHER, “Friendzy PARTIES”)
                FROM ANY CLAIM, COMPLAINT, CAUSE OF ACTION, CONTROVERSY, OR
                DISPUTE (TOGETHER, “CLAIM”) AND DAMAGES, KNOWN AND UNKNOWN,
                RELATING TO, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH ANY
                SUCH CLAIM YOU HAVE AGAINST ANY THIRD PARTIES.
              </p>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Limitation of liability.</b>{" "}
                THE Friendzy PARTIES WILL NOT BE LIABLE TO YOU FOR ANY LOST
                PROFITS OR CONSEQUENTIAL, SPECIAL, PUNITIVE, INDIRECT, OR
                INCIDENTAL DAMAGES RELATING TO, ARISING OUT OF, OR IN ANY WAY IN
                CONNECTION WITH OUR TERMS, US, OR OUR SERVICES, EVEN IF THE
                Friendzy PARTIES HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH
                DAMAGES. OUR AGGREGATE LIABILITY RELATING TO, ARISING OUT OF, OR
                IN ANY WAY IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES
                WILL NOT EXCEED ONE HUNDRED DOLLARS ($100). THE FOREGOING
                DISCLAIMER OF CERTAIN DAMAGES AND LIMITATION OF LIABILITY WILL
                APPLY TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW. THE
                LAWS OF SOME STATES OR JURISDICTIONS MAY NOT ALLOW THE EXCLUSION
                OR LIMITATION OF CERTAIN DAMAGES, SO SOME OR ALL OF THE
                EXCLUSIONS AND LIMITATIONS SET FORTH ABOVE MAY NOT APPLY TO YOU.
                NOTWITHSTANDING ANYTHING TO THE CONTRARY IN OUR TERMS, IN SUCH
                CASES, THE LIABILITY OF THE Friendzy PARTIES WILL BE LIMITED TO
                THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW.
              </p>

              <p className="safty-para">
                <b style={{ fontWeight: "600" }}>Availability of Our Services. </b>
                Our Services may be interrupted, including for maintenance,
                upgrades, or network or equipment failures. We may discontinue
                some or all of our Services, including certain features and the
                support for certain devices and platforms, at any time.
              </p>
            </div>

            <div className="pt-3">
              <h5>Termination</h5>
              <p className="safty-para pt-2">
                <b style={{ fontWeight: "600" }}>Termination by You. </b>
                You may terminate your account at any time by contacting us
                through the support feature within the application.
              </p>

              <p className="safty-para pt-2">
                <b style={{ fontWeight: "600" }}>Termination by Us. </b>
                We may terminate or suspend your account or access to the
                application at any time, with or without notice, for any reason,
                including if we believe you have violated these Terms.
              </p>
            </div>

            <div className="pt-3">
              <h5>General</h5>

              <p className="safty-para pt-2">
                Friendzy may update the Terms from time to time. When we update
                our Terms, we will update the “Last Modified” date associated
                with the updated Terms. Your continued use of our Services
                confirms your acceptance of our updated Terms and supersedes any
                prior Terms. You will comply with all applicable export control
                and trade sanctions laws. Our Terms cover the entire agreement
                between you and Friendzy regarding our Services. If you do not
                agree with our Terms, you should stop using our Services.
              </p>
              <p className="safty-para">
                If we fail to enforce any of our Terms, that does not mean we
                waive the right to enforce them. If any provision of the Terms
                is deemed unlawful, void, or unenforceable, that provision shall
                be deemed severable from our Terms and shall not affect the
                enforceability of the remaining provisions. Our Services are not
                intended for distribution to or use in any country where such
                distribution or use would violate local law or would subject us
                to any regulations in another country. We reserve the right to
                limit our Services in any country.
                {/* If you have specific
                questions about these Terms, please contact us at
                privacy@Friendzy.org. */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Term;
