import React, { useEffect, useRef, useState } from "react";
import logoHeader from "../../assets/images/friendzy-logo.png";
import "./Header.css";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link } from "react-scroll";
import { Link as NewLink } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Header() {
  const headerRef = useRef();
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const path = location.pathname;

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const closeMenu = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (headerRef.current) {
        if (window.scrollY > 80) {
          headerRef.current.classList.add("sticky");
        } else {
          headerRef.current.classList.remove("sticky");
        }
      }
    };

    const debounce = (func, wait = 20, immediate = true) => {
      let timeout;
      return function () {
        const context = this,
          args = arguments;
        const later = () => {
          timeout = null;
          if (!immediate) func.apply(context, args);
        };
        const callNow = immediate && !timeout;
        clearTimeout(timeout);
        timeout = setTimeout(later, wait);
        if (callNow) func.apply(context, args);
      };
    };

    const debouncedHandleScroll = debounce(handleScroll);
    window.addEventListener("scroll", debouncedHandleScroll);

    return () => window.removeEventListener("scroll", debouncedHandleScroll);
  }, []);
  return (
    <header
      className={`${path === "/" ? "header-main" : " safty"}`}
      ref={headerRef}
    >
      <Navbar expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand href="#">
            <NewLink to="/" className="logo-link">
              <img src={logoHeader} className="header-logo" alt="" />
              <span className="friendzy-logo">Friendzy</span>
            </NewLink>
          </Navbar.Brand>
          <button
            className={`navbar-toggler`}
            type="button"
            onClick={toggleMenu}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <Navbar.Collapse
            id="navbarScroll"
            className={`collapse navbar-collapse ${isOpen ? "show" : ""}`}
          >
            <Nav className="header-link">
              {path === "/" ? (
                <>
                  <Nav.Link>
                    <Link
                      to="Home"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={1}
                      onClick={closeMenu}
                    >
                      Home
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link
                      to="Features"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={1}
                      onClick={closeMenu}
                    >
                      Features
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link
                      to="About"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={1}
                      onClick={closeMenu}
                    >
                      About
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link
                      to="Screens"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={1}
                      onClick={closeMenu}
                    >
                      Screens
                    </Link>
                  </Nav.Link>
                  <Nav.Link>
                    <Link
                      to="Download"
                      spy={true}
                      smooth={true}
                      offset={-100}
                      duration={1}
                      onClick={closeMenu}
                    >
                      Download
                    </Link>
                  </Nav.Link>
                    <NewLink to="/contact_us" className="nav-link">
                      Contact
                    </NewLink>
                </>
              ) : (
                <>
                  <NewLink to="/" className="nav-link" onClick={closeMenu}>
                    Home
                  </NewLink>
                  <NewLink to="/" className="nav-link" onClick={closeMenu}>
                    Features
                  </NewLink>
                  <NewLink to="/" className="nav-link" onClick={closeMenu}>
                    About
                  </NewLink>
                  <NewLink to="/" className="nav-link" onClick={closeMenu}>
                    Screens
                  </NewLink>
                  <NewLink to="/" className="nav-link" onClick={closeMenu}>
                    Download
                  </NewLink>
                  <NewLink to="/contact_us" className="nav-link">
                    Contact
                  </NewLink>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
