import React from 'react'
import About from '../../About/About'
import Feature from '../../Feature1/feature'
import Home from '../../Home/Home'
import Footer from '../Footer/Footer'
import Header from '../Header/Header'
import Screen from '../../Screen/Screen'
import Download from '../../Download/Download'
import Privacy from '../../Privacy/Privacy'
import { Outlet } from 'react-router-dom'

function Layout() {

  return (
    <>
      <Header/>
      <Outlet/>
      <Footer/>
    </>
  )
}

export default Layout
