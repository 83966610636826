import React from "react";
import { Link } from "react-router-dom";
import "./Privacy.css";

function PrivacySafety() {
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="safty-data">
              <div className="">
                <div className="privacy-heading">Privacy Policy</div>
                <p className="safty-para">
                  Thank you for choosing to use Friendzy App. Friendzy is a
                  comprehensive chat application that facilitates private
                  messaging and connection opportunities for users worldwide.
                  Our platform offers a range of features designed to enhance
                  your communication experience. This Privacy Policy explains
                  how we collect, use, and disclose information when you use our
                  Application. By using the Application, you consent to the
                  practices described in this Privacy Policy.
                </p>
              </div>
              <div className="">
                <h5>Information We Collect</h5>
                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Account Information. </b>
                  You register an email address when you create a Friendzy
                  account. Email addresses are used to provide our Services to
                  you. We may collect personal information such as your name,
                  email address, date of birth, gender, and location. However,
                  providing your date of birth, gender, and location is not
                  compulsory.
                </p>
                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Messages. </b>
                  Friendzy stores your messages on your devices and temporarily
                  on its servers to ensure they are delivered when the receiving
                  device is available. Your message history is retained on your
                  devices.
                </p>

                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Additional Information. </b>
                  Friendzy stores the minimum necessary technical information
                  required to operate the services effectively.
                </p>
                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>User Support. </b>
                  If you contact Friendzy User Support, any personal data you
                  may share with us is kept only for the purposes of researching
                  the issue and contacting you about your case.
                </p>

                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>
                    Managing your information.
                  </b>{" "}
                  You can manage your personal information in Friendzy’s
                  application settings. For example, you can update your profile
                  information, temporarily deactivate your account, or delete
                  your account.
                </p>
              </div>

              <div className="">
                <h5>Services</h5>
                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Minimum Age: </b> You must be
                  at least 18 years old to use our Services. The minimum age to
                  use our Services without parental approval may be higher in
                  your home country.
                </p>
                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Account Registration: </b>
                  To create an account you must register for our services using
                  your email address.
                </p>

                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Privacy of User data: </b>{" "}
                  Friendzy does not sell, rent or monetize your personal data or
                  content in any way – ever.
                </p>

                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Location Information: </b> To
                  provide you with relevant features like the “Nearby Users”
                  system, we may collect and use your device’s location
                  information. We will request your consent before accessing
                  precise location data.
                </p>

                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Notifications: </b>
                  If you opt-in to receive push notifications, we may collect
                  and store device tokens and other information necessary to
                  send you push notifications.
                </p>

                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Online Users:</b> Our app
                  shows a list of online users, making it easier for you to find
                  and connect with others.
                </p>

                <p className="safty-para">
                  Please read our Privacy Policy to understand how we safeguard
                  the information you provide when using our Services. For the
                  purpose of operating our Services, you agree to our data
                  practices as described in our Privacy Policy.
                </p>
              </div>

              <div className="">
                <h5>Your Rights and Choices</h5>
                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Access and Update : </b> You
                  can access and update your profile information at any time
                  through the application settings.
                </p>
                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Privacy Settings:</b> If you
                  block a user, neither of you will be able to see each other's
                  profiles, ensuring a mutual block for enhanced privacy and
                  security.
                </p>

                <p className="safty-para">
                  <b style={{ fontWeight: "600" }}>Data Deletion:</b> Users of
                  the Application have the option to delete their accounts at
                  any time. This can be done through the account settings or by
                  contacting our support team.
                </p>
              </div>

              <div className="pt-3">
                <h5>Information we may share</h5>
                <p className="pt-2">
                  <b>Profile Information:</b>
                </p>
                <ul>
                  <li>
                    Your profile information, including photos, about, and
                    location, is visible to other users of the application.
                  </li>
                </ul>
                <p className="pt-2">
                  <b>
                    Other instances where Friendzy may need to share your data
                  </b>
                </p>
                <ul>
                  <li>
                    To meet any applicable law, regulation, legal process or
                    enforceable governmental request.
                  </li>
                  <li>
                    To enforce applicable Terms, including investigation of
                    potential violations.
                  </li>
                  <li>
                    To detect, prevent, or otherwise address fraud, security, or
                    technical issues.
                  </li>
                  <li>
                    To protect against harm to the rights, property, or safety
                    of Friendzy, our users, or the public as required or
                    permitted by law.
                  </li>
                </ul>
              </div>

              <div className="pt-3">
                <h5>Updates</h5>
                <p className="safty-para pt-2">
                  We may update this Privacy Policy from time to time. We will
                  notify you of any material changes by posting the updated
                  Privacy Policy within the Application or by other means. Your
                  continued use of the Application after the effective date of
                  the updated Privacy Policy constitutes your acceptance of the
                  changes.
                </p>
              </div>
              <div className="pt-3">
                <h5>Terms</h5>
                <p className="safty-para pt-2">
                  Please also read our Terms which also governs the{" "}
                  <Link to="/termsandcondition">terms</Link> of this Privacy
                  Policy.
                </p>
              </div>
              <div className="pt-3">
                <h5>Contact Us</h5>
                <p className="safty-para pt-2">
                  If you have any questions or concerns about this Privacy
                  Policy, please contact us at{" "}
                  <Link to="/contact_us">contact@friendzy.world.</Link>
                </p>

                <p className="safty-para">Effective as of March 25, 2024</p>
                <p className="safty-para">Updated July 23, 2024</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacySafety;
