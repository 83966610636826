import React, { useEffect } from "react";
import { Route, Routes } from "react-router";
import "./App.css";
import Layout from "./component/Layout/Layout";
import Main from "./component/Main";
import RequestForm from "./plyastor/ReqestForm";
import PrivacySafety from "./Privacy/PrivacySafety";
import Term from "./Term&Condition/Term";
import { useLocation } from "react-router-dom";
import Contact from "./Contact/Contact";

function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top when the location changes
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/account_delete_request" element={<RequestForm />} />
        <Route path="/privacypolicy" element={<PrivacySafety />} />
        <Route path="/termsandcondition" element={<Term />} />
        
        <Route element={<Layout />}>
          <Route path="/" element={<Main />} />
          <Route path="/contact_us" element={<Contact />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
