import axios from "axios";

export const axiosHelper = async (url, method, body, accessToken) => {
  try {
    const api = "https://friendzy.world:7800" + url;
    const headers = {
      "Content-Type": "application/json",
    };

    // Add Authorization header only if the 'header' value is provided
    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
    }

    const response = await axios({
      method: method,
      url: api,
      headers: headers,
      data: body !== undefined && body !== null ? body : undefined,
    });

    const result = response.data;
    return result;
  } catch (error) {
    console.log(error);
  }
};

