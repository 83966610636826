import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Privacy.css";
import usephone from "../assets/images/use-phone.png";
import { motion } from "framer-motion";
import fadeIn from "../variants";

function Privacy() {
  return (
    <>
      <Container id="Privacy&Safety">
        <Row className="privacy-main">
          <Col lg="6" md="6">
            <motion.div
              variants={fadeIn("up", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
            >
              <img src={usephone} className="privacy-img " />
            </motion.div>
          </Col>
          <Col lg="6" md="6">
            <motion.div
              className="privacy-data"
              variants={fadeIn("down", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
            >
              <h4 className="privacy-heading1">Connect with Confidence</h4>
              <div className="head-data1">
                Messenger has everything you need to feel closer to your
                favourite people.
              </div>
            </motion.div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Privacy;
