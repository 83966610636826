import React from 'react'
import { Col } from 'react-bootstrap'
import './Contact.css'

function Contact() {
  return (
    <>
     <div className='container contact'>
      <div className='row'>
        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
          <div className='x_content'>
           <div className='row contact-data'>
           <div className='col-md-6 col-sm-6 col-xs-12 contact-txt'>
              <h3>Contact us</h3>
              <p>Need to get in touch with us ? Either fill out the form from with your inquiry or find the department email you'd link to contact below</p>
            </div>

            <div className='col-md-1 col-sm-1 col-xs-12'></div>

            <div className='col-md-5 col-sm-5 col-xs-12'>
            <form id="new-payee-frm" className='contact-data1' >
                    <div className="row">    
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group ">
                          <label className="form-lbl">Name :</label>
                          <input
                            type="text"
                            className="form-control "
                            name="name"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 col-xs-12">
                        <div className="form-group ">
                          <label className="form-lbl">Email :</label>
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            required
                          />
                        </div>
                      </div>
                   
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group ">
                          <label className="form-lbl">Phone :</label>
                          <br></br>
                          <input
                            type="text"
                            className="form-control "
                            name="phone"
                            pattern="[0-9]{10}"
                            required
                          />
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="form-group ">
                          <label className="form-lbl">Message :</label>
                          <br></br>
                          <textarea id="noter-text-area" name="textarea" className="form-control "  required ></textarea>
                        </div>
                      </div>
                      <Col
                        lg="12"
                        md="12"
                        style={{ display: "flex", justifyContent: "start" }}
                      >
                        <button type="submit" className="btn submit-btn">
                          Submit
                        </button>
                      </Col>
                    </div>
                  </form>
            </div>
           </div>
          </div>
        </div>
      </div>
     </div>
    </>
  )
}

export default Contact
