import React from "react";
import "./feature.css";
import fea1 from "../assets/images/aa.png";
import fea2 from "../assets/images/bb.jpeg";
import { Col, Container, Row } from "react-bootstrap";
import video1 from "../assets/Video/video1.mp4";
import { motion } from "framer-motion";
import fadeIn from "../variants";

function Feature() {
  return (
    <>
      <Container className="py-3" id="Features">
        <Row>
          <Col lg="4" md="4" className="">
            <motion.div
              variants={fadeIn("left", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="fe"
            >
              <h4 className="feature-heading1">More ways to stay connect.</h4>
              
              <div className="head-data1">
                Messenger has everything you need to feel closer to your
                favourite people.
              </div>
            </motion.div>
          </Col>
          <Col lg="8" md="8" className="feature-img">
            <motion.div
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
              className="fe"
            >
              <img src={fea1} className="fea-img" />
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col
            lg="6"
            md="6"
            sm="6"
            style={{
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <motion.div
              className="video-fix-height fe"
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              viewport={{ once: true, amount: 0.7 }}
            >
              <video autoPlay muted loop height="600">
                <source src={video1} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </motion.div>
          </Col>
          <Col lg="6" md="6" className="fea-heading-main">
            <motion.div
              variants={fadeIn("left", 0.2)}
              initial="hidden"
              whileInView={"show"}
              className="fe"
              viewport={{ once: true, amount: 0.7 }}
            >
              <div className=""> CUSTOM REACTIONS</div>
              <div className="feature-heading2">
                Say it with <br /> any emoji
              </div>
              <div className="fea-heading-data">
                Lost for words? Now you can customise your reactions <br /> with
                way more emojis to choose from, including 🎉 and 🔥.
              </div>
            </motion.div>
          </Col>
        </Row>
        <Row>
          <Col lg="6" md="6" className="fea-heading-main">
            <motion.div
              variants={fadeIn("left", 0.2)}
              initial="hidden"
              whileInView={"show"}
              className="fe"
              viewport={{ once: true, amount: 0.7 }}
            >
              <div className=""> ANIMATED EFFECTS</div>
              <div className="feature-heading2">Express yourself</div>
              <div className="fea-heading-data">
                Bring your conversations to life with fun AR effects, message
                effects <br /> and selfie stickers*.
              </div>
            </motion.div>
          </Col>
          <Col lg="6" md="6">
            <motion.div
              variants={fadeIn("right", 0.2)}
              initial="hidden"
              whileInView={"show"}
              className="fe"
              viewport={{ once: true, amount: 0.7 }}
            >
              <img src={fea2} className="fea-img" />
            </motion.div>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Feature;
