import React from "react";
import "./Download.css";
import backimg from "../assets/images/download-2.jpg";
import qrcode from "../assets/images/qrcode.png";
import mobile from "../assets/images/downloadmob.png";
import { Button, Col, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import fadeIn from "../variants";
import { IoLogoAndroid } from "react-icons/io";
import { IoLogoApple } from "react-icons/io";

function Download() {
  return (
    <>
      <div className="download-main" id="Download">
        <img src={backimg} className="backimg" />
        <div className="download-data">
          <Row className="d-subdata">
            <Col md="7" lg="7" className="d-heading">
              <div className="d-data">
                <div className="download-link">
                  <img src={qrcode} className="qr-img" />
                  <div className="qr-data">
                    Scran QR CODE To <br /> Get Link,
                    <br />
                    <span style={{ fontSize: "1.7rem", color: "#ffff1a" }}>
                      Download Now !
                    </span>
                  </div>
                </div>
              </div>
              <motion.div
                className="dow-img"
                variants={fadeIn("right", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
              >
                <div className="android-icon">
                  <IoLogoAndroid style={{ fontSize: "2.5rem" }} />
                  <div className="and-icondata">
                    <span className="and-icontext">Downloads for </span>
                    <span className="icon-heading"> ANDROID USER</span>
                  </div>
                </div>
                <div className="android-icon">
                  <IoLogoApple style={{ fontSize: "2.5rem" }} />
                  <div className="and-icondata">
                    <span className="and-icontext">Downloads for </span>
                    <span className="icon-heading"> IPHONE USER</span>
                  </div>
                </div>
              </motion.div>
            </Col>
            <Col md="5" lg="5">
              <motion.div
                variants={fadeIn("left", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
              >
                <img src={mobile} className="down-mob" />
              </motion.div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Download;
