import React from "react";
import "./Home.css";
import mobile from "../assets/images/mob-banner1.png";
import { Button, Col, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import fadeIn from "../variants";
import { Link } from "react-scroll";
import { TypeAnimation } from "react-type-animation";

function Home() {
  return (
    <>
      <div
        className="home-main"
        data-animation="fadeInRight"
        data-animation-delay="500"
        id="Home"
      >
        <div className="home-data">
          <Row className="home-subdata">
            <Col md="6" lg="6" className="banner-heading">
              <motion.div
                className="banner-data"
                variants={fadeIn("up", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
              >
                <TypeAnimation
                  sequence={[
                    "Mobile Chat", 
                    500, 

                    () => {
                      console.log("Sequence completed");
                    },
                  ]}
                  speed={{ type: "keyStrokeDelayInMs", value: 250 }}
                  wrapper="span"
                  cursor={true}
                  repeat={Infinity}
                />
                <div className="banner-para">
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Quos,
                  quam architecto praesentium, cum quasi maxime et, eum
                  perferendis alias vitae sit amet voluptatum ipsa.{" "}
                </div>
                <Link
                  to="About"
                  spy={true}
                  smooth={true}
                  offset={-90}
                  duration={1}
                >
                  <div className="btn learn-btn"> Learn More</div>
                </Link>
              </motion.div>
            </Col>
            <Col md="6" lg="6">
              <motion.div
                variants={fadeIn("down", 0.2)}
                initial="hidden"
                whileInView={"show"}
                viewport={{ once: true, amount: 0.7 }}
              >
                <img src={mobile} className="mobile-img" />
              </motion.div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Home;
